<template>
  <v-row class="mx-0">
    <v-col cols="12">
      <v-toolbar color="transparent mt-1" flat height="48">
        <v-toolbar-title class="ml-n3 subtitle-1">
          Reportes
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          <span class="text-decoration-none secondary--text font-weight-bold">Transacciones SII</span>
        </v-toolbar-title>
      </v-toolbar>
    </v-col>
    <v-col>
      <v-card class="mb-5" flat>
        <v-card-text class="px-0">
          <!-- documents status -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-toolbar color="transparent" flat height="48">
                <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Resultado de revisión SII</v-toolbar-title>
                <v-spacer />
                <v-chip color="primary" text-color="white" small>Beta</v-chip>
              </v-toolbar>
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <template v-for="(status, i) in statusesDocumentsStatus">
              <v-col :key="`status-${i}`">
                <v-sheet class="py-5 py-0 text-center">
                  <v-progress-circular class="mb-6" v-if="loaderReport" indeterminate color="primary" :width="2" :size="24" />
                  <span class="d-block mb-4 headline text--secondary font-weight-regular" v-else>{{valuesDocumentsStatus[status.code] | number}}</span>
                  <span class="d-block body-1 text--secondary font-weight-medium">{{status.name}}</span>
                  <v-btn :to="{name: 'DocumentsList', query: {tax_agency__contains: status.routeQueryValue}}" exact color="primary" text small :ripple="false">Ver facturas</v-btn>
                </v-sheet>
              </v-col>
              <v-divider v-if="i < statusesDocumentsStatus.length - 1" :key="`status-divider-${i}`" vertical />
            </template>
          </v-row>
          <!-- end documents status -->

          <!-- acknowledgment -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-divider />
              <v-toolbar color="transparent" flat height="48">
                <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Acuse de recibo</v-toolbar-title>
              </v-toolbar>
              <v-divider />
            </v-col>
          </v-row>
          <v-row>
            <template v-for="(status, i) in statusesAcknowledgment">
              <v-col :key="`status-${i}`">
                <v-hover v-slot="{hover}">
                  <v-sheet class="pt-5 py-0 text-center">
                    <v-progress-circular class="mb-6" v-if="loaderReport" indeterminate color="primary" :width="2" :size="24" />
                    <span class="d-block mb-4 headline text--secondary font-weight-regular" v-else>{{valuesAcknowledgment[status.code] | number}}</span>
                    <span class="d-block body-1 text--secondary font-weight-medium">{{status.code}}</span>
                    <span class="d-block body-2">{{status.name}}</span>
                    <v-btn :to="{name: 'IrsBuiltInReportTransactional', query: {code: status.code}}" exact :color="hover ? 'primary' : 'transparent'" text small :ripple="false">Filtrar registros</v-btn>
                  </v-sheet>
                </v-hover>
              </v-col>
              <v-divider v-if="i < statusesAcknowledgment.length - 1" :key="`status-divider-${i}`" vertical />
            </template>
          </v-row>
        </v-card-text>
      </v-card>
      <v-skeleton-loader v-if="loaderList" transition="fade-transition" type="table" />
      <v-data-table v-else :headers="headers" :items="supplierresponsesList" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(supplierresponsesList) => {$router.push({name: 'DocumentsRetrieve', params: {id: supplierresponsesList.documents.id}})}" style="cursor: pointer" no-data-text="No hay datos disponibles">
        <template v-slot:header="{props: {headers}}">
          <v-table-headers :headers="headers" @sort="setSort" />
        </template>
        <template v-slot:[`item.document`]="{item}">
          <span class="d-block">{{item.documents.document.name}}</span>
          <span class="d-block body-2 text--secondary font-weight-medium">Nº {{item.documents.number}}</span>
        </template>
        <template v-slot:[`item.status`]="{item}">
          <v-chip v-if="item.documents.status === 'finalized'" class="mx-1" color="defaultGrey" text-color="secondary" small>Finalizado</v-chip>
          <v-chip v-else-if="item.documents.status === 'paid'" class="mx-1" color="defaultGrey" text-color="secondary" small>Pagado<v-icon class="ml-1" x-small>mdi-check</v-icon></v-chip>
          <v-chip v-else-if="item.documents.status === 'cancelled'" class="mx-1" color="defaultGrey" text-color="secondary" small>Tiene nota de crédito<v-icon class="ml-1" x-small>mdi-close</v-icon></v-chip>
        </template>
        <template v-slot:[`item.date`]="{item}">
          <span>{{item.documents.date | date}}</span>
        </template>
        <template v-slot:[`item.total`]="{item}">
          <span class="body-2 text--secondary font-weight-medium">{{item.documents.total | currency(item.documents.currency)}}</span>
          <span class="ml-1">{{item.documents.currency}}</span>
        </template>
      </v-data-table>
      <v-row class="pl-4 pr-3" align="center">
        <v-col cols="6">
          <span class="body-2 text--secondary">{{count}} registros</span>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-pagination v-model="page" :length="totalPages" total-visible="6" />
        </v-col>
      </v-row>
       <!-- end acknowledgment -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'

export default {
  components: {
    VTableHeaders
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    headers: [
      { text: 'Estado de respuesta', value: 'code', class: 'font-weight-bold', sortable: true },
      { text: 'Documento', value: 'document' },
      { text: '', value: 'status' },
      { text: 'Fecha', value: 'date', align: 'end', sortable: true },
      { text: 'Total', value: 'total', align: 'end', sortable: true }
    ],
    loaderList: false,
    loaderReport: false,
    statusesAcknowledgment: [
      {
        code: 'RCD',
        name: 'Reclamación por el contenido del documento'
      },
      {
        code: 'RFP',
        name: 'Reclamación por falta parcial de productos'
      },
      {
        code: 'RFT',
        name: 'Reclamación por falta total de productos'
      }
    ],
    valuesAcknowledgment: { ERM: 0, ACD: 0, RCD: 0, RFP: 0, RFT: 0, WAR: 0 },
    statusesDocumentsStatus: [
      {
        code: 'approved',
        name: 'Facturas aceptadas',
        routeQueryValue: '{"cl_status_detail": "Aceptado"}'
      },
      {
        code: 'rejected',
        name: 'Facturas rechazadas',
        routeQueryValue: '{"cl_status_detail": "Rechazado"}'
      }
    ],
    valuesDocumentsStatus: { approved: 0, rejected: 0 }
  }),
  computed: mapState({
    count: state => state.irs.supplierresponsesCount,
    supplierresponsesList: state => state.irs.supplierresponsesList
  }),
  created () {
    this.loaderReport = true

    Promise.all([
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/supplierresponses/report'
      })
      .then((response) => {
        if (response.data) {
          const dataset = response.data.datasets[0].data
          const labels = response.data.labels
          this.valuesAcknowledgment = Object.fromEntries(labels.map((v, i) => [v, dataset[i]]))
        }
      }),
      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/documents_status/report'
      })
      .then((response) => {
        if (response.data) {
          const dataset = response.data.datasets[0].data
          const labels = response.data.labels
          this.valuesDocumentsStatus = Object.fromEntries(labels.map((v, i) => [v, dataset[i]]))
        }
      })
    ])
    .finally(() => {
      setTimeout(() => {
        this.loaderReport = false
      }, 1000)
    })
  },
  methods: {
    getList () {
      const query = Object.assign({}, this.$route.query, {
        expand: 'documents'
      })
      this.loaderList = true

      if (!this.$route.query.code) {
        query.code__in = 'RCD,RFP,RFT'
      }

      this.$store.dispatch('irs/LIST', {
        resource: 'irs/sii/supplierresponses',
        query: query
      })
      .finally(() => {
        this.loaderList = false
      })
    }
  }
}
</script>